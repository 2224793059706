/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import "regenerator-runtime"
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { useTable, useSortBy } from 'react-table';
import { Link, navigate } from 'gatsby';
import { Box, BoxVisible, Flex } from '../../style/basicStyle';
import toast from '../../utils/toast';
import { withFirebase } from '../Firebase';
import { breadCrumb, breadcrumbFix, inline } from '../../style/breadCrumb';
import { exBarBar, exBarContent, exBarItem, exBarLink } from '../../style/mouseOverUnderline';
import { getHiddenColumns } from "../TableFilters/TableFilters";

const TableStyles = styled.div`
  padding: 0rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, initialState }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: getHiddenColumns(columns),
        sortBy: [
          {
            id: 'name',
            desc: true
          }
        ]
      }
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const orderRecord = (order) => {
  return {
    id: order.id,
    name: order.name,
    quantity: order.quantity,
    description: order.description,
    price: order.price,
    totalPrice: order.totalPrice,
    customFields: order.customFields
  };
};

const columns = [
  {
    Header: 'Items',
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row: { original } }) => {
          const { name } = original;
          const { description } = original;
          const { customFields } = original;
          return (
            (
              <>
                <Box>{name}</Box>
                {description && description.length && (
                  <Box m={1} fontSize={[3]}>
                    {description}
                  </Box>
                )}
                {customFields && customFields.length && (
                  <Box m={1} fontSize={[2]}>
                    {customFields.map((customField, index) => {
                      if (customField.name && customField.name.length && customField.value) {
                        return (
                          <Box key={`${customField.name}-${index}`}>
                            {customField.name}: {customField.value}
                          </Box>
                        );
                      }
                      return <div key={`${customField.name}-${index}`} />;
                    })}
                  </Box>
                )}
              </>
            ) || null
          );
        }
      },
      {
        Header: 'SKU',
        accessor: 'id'
      },
      {
        Header: 'QTY',
        accessor: 'quantity'
      },
      {
        Header: 'Subtotal',
        accessor: 'totalPrice',
        Cell: ({ row: { original } }) => {
          const { totalPrice } = original;
          return (totalPrice && `$ ${totalPrice}`) || null;
        }
      }
    ]
  }
];

class ViewOrder extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);
    const { admin } = props;
    this.state = {
      admin: admin || false,
      idToken: undefined,
      loading: false,
      Order: {}
    };
  }

  componentDidMount() {
    this.getItemsList();
  }

  componentDidUpdate() {
    this.getItemsList();
  }

  getItemsList = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      const { orderId } = this.props;
      if (orderId) {
        this.props.firebase.auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            this.props.firebase.auth.currentUser
              // eslint-disable-next-line react/prop-types
              .getIdToken(/* forceRefresh */ true)
              .then(function getIdToken(idToken) {
                const values = { idToken, orderId };
                fetch('/.netlify/functions/getOrders', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(values)
                })
                  .then((result) => {
                    try {
                      const resultJSON = result.json();
                      resultJSON
                        .then(function processResult(r) {
                          if (result.status === 200) {
                            self.setState({
                              // eslint-disable-next-line react/no-unused-state
                              Order: r.message,
                              loading: false,
                              idToken
                            });
                          } else {
                            console.log('order request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                            toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                          }
                        })
                        .catch((error) => {
                          console.log('order request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                          toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                        });
                    } catch (e) {
                      console.log('order request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                      toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                    }
                  })
                  .catch((error) => {
                    console.log('order request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                    toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                  });
              })
              .catch(function getIdTokenError(error) {
                console.log('order request - getIdTokenError - error is: ', error);
              });
          }
        });
      } else {
        console.log('order request ERROR 5 - missing orderId!');
        toast(`Error: Missing Order Id!`);
      }
    }
  };

  formatTable() {
    const { state } = this;
    const { idToken } = state;
    const { Order } = state;
    const { items } = Order;
    const length = items && items.length;
    if (length) {
      const formatTableLevel = (depth = 0) => {
        return items.map((order) => {
          return {
            ...orderRecord(order, idToken),
            subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
          };
        });
      };
      return formatTableLevel();
    }
    return [];
  }

  render() {
    const { loading } = this.state;
    const { state } = this;
    const { Order } = state;
    const { admin } = state;
    return (
      <>
        <Box>
          {(loading && (
            <Flex wrap={['nowrap']}>
              <div css={[exBarItem, breadcrumbFix]}>
                <div css={[exBarContent, inline]}>Loading... </div>
                <Link to="/orders" css={exBarLink}>
                  <span css={exBarBar} />
                </Link>
              </div>
            </Flex>
          )) || (
            <Flex wrap={['nowrap']}>
              <Box m={[0]} width={[1, 1, 1]} p={[0]}>
                <div css={[exBarItem, breadcrumbFix]}>
                  <div css={[exBarContent, inline]}>Orders </div>
                  <Link to={(admin && '/ordersAdmin') || '/orders'} css={exBarLink}>
                    <span css={exBarBar} />
                  </Link>
                </div>
                &gt;
                <div css={[exBarItem, breadcrumbFix]}>
                  <div css={[exBarContent, inline]}>{Order.invoiceNumber} </div>
                  <Link to={`/viewOrder/${Order.docId}`} css={exBarLink}>
                    <span css={exBarBar} />
                  </Link>
                </div>
              </Box>
            </Flex>
          )}
          <Box fontSize={[2]}>
            <Box p={1}>Invoice #: {Order.invoiceNumber}</Box>
            <Box p={1}>Completed: {Order.completionDateFormatted}</Box>
            <Box p={1}>Status: {Order.status}</Box>
            <Box p={1}>
              Payment Method: {Order.paymentMethod}{' '}
              {Order.paymentMethod === 'CreditCard' && (
                <>
                  {' '}
                  - *{Order.creditCardLast4Digits} - {Order.cardType}
                </>
              )}
            </Box>
            <Box p={1}>Total Items: {Order.itemsCount}</Box>
          </Box>
          <Box p={1} fontSize={[2]}>
            {Order.trackingUrl && (
              <>
                Tracking #: <a href={Order.trackingUrl}>{Order.trackingNumber}</a>{' '}
              </>
            )}
          </Box>
        </Box>
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
        <Box my={3}>
          <Box>Order Total: ${Order.grandTotal}</Box>
        </Box>
        <Box fontSize={[2]}>
          <Box my={3}>{(Order.shippingAddressSameAsBilling && <>Shipping And Billing Address</>) || <>Shipping Address</>}</Box>
          <Box p={1}>{Order.shippingAddressName}</Box>
          {Order.shippingAddressPhone && Order.shippingAddressPhone.length && (
            <>
              <Box p={1}>{Order.shippingAddressPhone}</Box>
            </>
          )}
          {Order.shippingAddressAddress1 && Order.shippingAddressAddress1.length && (
            <>
              <Box p={1}>{Order.shippingAddressAddress1}</Box>
            </>
          )}
          {Order.shippingAddressAddress2 && Order.shippingAddressAddress2.length && (
            <>
              <Box p={1}>{Order.shippingAddressAddress2}</Box>
            </>
          )}
          <Box p={1}>
            {Order.shippingAddressCity}, {Order.shippingAddressProvince} {Order.shippingAddressPostalCode}
          </Box>
        </Box>
        {!Order.shippingAddressSameAsBilling && (
          <>
            <Box>
              <Box my={3}>Billing Address</Box>
              <Box p={1}>{Order.billingAddressName}</Box>
              {Order.billingAddressPhone && Order.billingAddressPhone.length && (
                <>
                  <Box p={1}>{Order.billingAddressPhone}</Box>
                </>
              )}
              {Order.billingAddressAddress1 && Order.billingAddressAddress1.length && (
                <>
                  <Box p={1}>{Order.billingAddressAddress1}</Box>
                </>
              )}
              {Order.billingAddressAddress2 && Order.billingAddressAddress2.length && (
                <>
                  <Box p={1}>{Order.billingAddressAddress2}</Box>
                </>
              )}
              <Box p={1}>
                {Order.billingAddressCity}, {Order.billingAddressProvince} {Order.billingAddressPostalCode}
              </Box>
            </Box>
          </>
        )}
      </>
    );
  }
}

export default withFirebase(ViewOrder);
