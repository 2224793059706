import React from 'react';
import { compose } from 'recompose';
import { graphql, navigate } from 'gatsby';
import LayoutWithAuth from '../../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../../components/Session';
import { ViewOrder } from '../../components/ViewOrder';
import { Box, Flex } from '../../style/basicStyle';

// eslint-disable-next-line react/prop-types
const ViewOrderPageBase = ({ props }) => {
  // eslint-disable-next-line react/prop-types
  const { orderId } = props;
  // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  const { location } = props;
  // eslint-disable-next-line react/prop-types
  const { state } = location;
  // eslint-disable-next-line react/prop-types
  const { admin } = state;
  return (
    <Box px={[2, 3, 5]}>
      <ViewOrder orderId={orderId} admin={admin} />
    </Box>
  );
};

const condition = (authUser) => authUser;

const ViewOrderPage = compose(withEmailVerification, withAuthorization(condition))(ViewOrderPageBase);

// eslint-disable-next-line react/prop-types
const Named = (props) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth>
        <ViewOrderPage props={props} />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query ViewOrderContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "ViewOrder" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
